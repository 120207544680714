import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'PoemsView',
    data() {
        return {
            poem: {},
            currentPoemId: null,
            fileAudio: null,
            fileCoverImage: null,
            fileImage: null
        }
    },
    mixins: [currentRouteMixin],
    components: {
        adminLayout
    },
    mounted() {
        this.currentPoemId = this.currentRoute().params.id

        this.getPoem()
    },
    methods: {
        getPoem() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/poems/' + self.currentPoemId,
                method: 'GET'
            }).then(async function(response) {
                self.poem = response.data.poem

                if(self.poem.youtube_id) {
                    self.poem.youtube_link = 'https://www.youtube.com/watch?v=' + self.poem.youtube_id
                }
            })
        },
        deletePoem() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/poems/' + self.currentPoemId,
                method: 'DELETE'
            }).then(function() {
                self.$router.push({ name: 'admin.poems' })
            })
        },
        updatePoem() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            formData.append('name', self.poem.name)
            formData.append('body', self.poem.body)
            formData.append('author', self.poem.author)
            if(self.fileCoverImage) formData.append('cover_image', self.fileCoverImage)
            if(self.fileImage) formData.append('image', self.fileImage)
            if(self.fileAudio) formData.append('audio', self.fileAudio)
            if(self.poem.youtube_link) formData.append('youtube_link', self.poem.youtube_link)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/poems/' + self.currentPoemId,
                method: 'POST',
                data: formData
            }).then(async function(response) {
                self.poem = response.data.poem

                if(self.poem.youtube_id) {
                    self.poem.youtube_link = 'https://www.youtube.com/watch?v=' + self.poem.youtube_id
                }

                self.notifySuccess()
            })
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        },
        handleFileCoverImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileCoverImage = null
            }

            this.fileCoverImage = files[0]
        },
        handleFileImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileImage = null
            }

            this.fileImage = files[0]
        }
    }
}